.icon-container{
    text-align: center;
    padding: 10px 20px 10px 0px;
    display: inline;
    i{
        font-size: 20px;
        color: #67757c;
    }
}
.resource-card{
    box-shadow: 1px 0px 20px #eaeaea;
    padding: 32px;
    margin-top: 32px;
}
.actual-resource-card{
    box-shadow: 1px 0px 4px #eaeaea;
    padding: 16px;
    margin-top: 16px;
}
