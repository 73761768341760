.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
}

.form-group {
  input::placeholder {
    color: #99abb4;
  }
  textarea::placeholder {
    color: #99abb4;
  }
  label {
    font-weight: bold;
  }
}

.text-error {
  color: red;
  margin: 32px 0 0 0;
}

.btn-rounded.btn-lg {
  font-size: 14px;
  font-weight: bold;
}

.action-link {
  font-size: 14px;

  &:hover {
    color: #398bf7;
  }

  span {
    font-weight: bold;
  }
}

.form-material .form-control {
  // padding-left: 16px !important;
}

.back-btn{
  height: 30px;
  width: 20px;
  line-height: 30px;
  cursor: pointer;
}

.modal-signup {
  max-width: 95vw !important;
}

.modal-image {
  height: 60vh;
  background-image: url('../../assets/images/creo_registro_baner.jpg'); 
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media only screen and (max-width: 800px) {
  .modal-image {
    height: 90vw;
    background-image: url('../../assets/images/creo_registro.jpg'); 
  }
}
.separator{
  height: 75px;
}