/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/


/*******************
Main sidebar
******************/

@import "./_variable.scss";
.left-sidebar {
	position: absolute;
	width: 240px;
	height: 100%;
	top: 0px;
	z-index: 20;
	padding-top: 55px;
	background: $sidebar;
	box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.fix-sidebar .left-sidebar {
	position: fixed;
}


/*******************
use profile section
******************/

.sidebar-nav .user-profile {
	>a {
		img {
			width: 30px;
			border-radius: 100%;
			margin-right: 10px;
		}
	}
	>ul {
		padding-left: 40px;
	}
}


/*******************
sidebar navigation
******************/

.scroll-sidebar {
	height: calc(100% - 70px);
	&.ps .ps__scrollbar-y-rail {
		left: 2px;
		right: auto;
		background: none;
		width: 6px;
		/* If using `left`, there shouldn't be a `right`. */
	}
}

.collapse.in {
	display: block;
}

.sidebar-nav {
	background: $sidebar;
	padding: 15px 0 0 0px;
	.selected-tab{
		background-color: #ff5050 !important;
		a{
			color: #fff !important; 
		}
	}
	ul {
		margin: 0px;
		padding: 0px;
		li {
			list-style: none;
			a {
				color: $sidebar-text;
				padding: 8px 35px 8px 15px;
				display: block;
				font-size: 16px;
				font-weight: 400;
				&.active,
				&:hover {
					color: $themecolor;
					i {
						color: $themecolor;
					}
				}
				&.active {
					font-weight: 500;
					color: $dark;
				}
			}
			ul {
				padding-left: 28px;
				li a {
					padding: 7px 35px 7px 15px;
				}
				ul {
					padding-left: 15px;
				}
			}
			&.nav-small-cap {
				font-size: 12px;
				margin-bottom: 0px;
				padding: 14px 14px 14px 20px;
				color: $dark;
				font-weight: 500;
			}
			&.nav-devider {
				height: 1px;
				background: $border;
				display: block;
				margin: 15px 0;
			}
		}
	}
	>ul>li>a {
		i {
			width: 31px;
			font-size: 24px;
			display: inline-block;
			vertical-align: middle;
			color: $sidebar-icons;
		}
		.label {
			float: right;
			margin-top: 6px;
		}
		&.active {
			font-weight: 400;
			background: #242933;
			color: $themecolor-alt;
		}
	}
	>ul>li {
		margin-bottom: 5px;
		&.active>a {
			color: $themecolor;
			font-weight: 500;
			i {
				color: $themecolor;
			}
		}
	}
	.waves-effect {
		transition: none;
		-webkit-transition: none;
		-o-transition: none;
	}
}

.sidebar-nav .has-arrow {
	position: relative;
	&::after {
		position: absolute;
		content: '';
		width: 7px;
		height: 7px;
		border-width: 1px 0 0 1px;
		border-style: solid;
		border-color: $sidebar-text;
		right: 1em;
		-webkit-transform: rotate(135deg) translate(0, -50%);
		-ms-transform: rotate(135deg) translate(0, -50%);
		-o-transform: rotate(135deg) translate(0, -50%);
		transform: rotate(135deg) translate(0, -50%);
		-webkit-transform-origin: top;
		-ms-transform-origin: top;
		-o-transform-origin: top;
		transform-origin: top;
		top: 47%;
		-webkit-transition: all .3s ease-out;
		-o-transition: all .3s ease-out;
		transition: all .3s ease-out;
	}
}

.sidebar-nav .active>.has-arrow::after,
.sidebar-nav li>.has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
	-webkit-transform: rotate(-135deg) translate(0, -50%);
	-ms-transform: rotate(-135deg) translate(0, -50%);
	-o-transform: rotate(-135deg) translate(0, -50%);
	top: 45%;
	width: 7px;
	transform: rotate(-135deg) translate(0, -50%);
}


/****************
When click on sidebar toggler and also for tablet
*****************/
//
// @media(min-width:768px) {
// 	.mini-sidebar .sidebar-nav #sidebarnav li {
// 		position: relative;
// 	}
// 	.mini-sidebar .sidebar-nav #sidebarnav>li>ul {
// 		position: absolute;
// 		left: 70px;
// 		top: 46px;
// 		width: 220px;
// 		padding-bottom: 10px;
// 		z-index: 1001;
// 		background: $sidebar-alt;
// 		display: none;
// 		padding-left: 1px;
// 	}
// 	.mini-sidebar .user-profile .profile-img {
// 		width: 45px;
// 		.setpos {
// 			top: -35px;
// 		}
// 	}
// 	.mini-sidebar.fix-sidebar .left-sidebar {
// 		position: fixed;
// 	}
// 	.mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul {
// 		height: auto !important;
// 		overflow: auto;
// 	}
// 	.mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul,
// 	.mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul.collapse {
// 		display: block;
// 	}
// 	.mini-sidebar .sidebar-nav #sidebarnav>li>a.has-arrow:after {
// 		display: none;
// 	}
// 	.mini-sidebar {
// 		.left-sidebar {
// 			width: 70px;
// 		}
// 		.sidebar-nav #sidebarnav .user-profile>a {
// 			padding: 12px 20px;
// 		}
// 		.scroll-sidebar {
// 			padding-bottom: 0px;
// 			position: absolute;
// 		}
// 		.hide-menu,
// 		.nav-small-cap,
// 		.sidebar-footer,
// 		.user-profile .profile-text,
// 		>.label {
// 			display: none;
// 		}
// 		.nav-devider {
// 			width: 70px;
// 		}
// 		.sidebar-nav {
// 			background: transparent;
// 		}
// 		.sidebar-nav #sidebarnav>li>a {
// 			padding: 12px 24px;
// 			width: 70px;
// 		}
// 		.sidebar-nav #sidebarnav>li:hover>a {
// 			width: 290px;
// 			background: $sidebar-alt;
// 			.hide-menu {
// 				display: inline;
// 			}
// 			.label {
// 				display: none;
// 			}
// 		}
// 	}
// }
//
// @media(max-width:767px) {
// 		.left-sidebar {
// 			position: fixed;
// 		}
//     .left-sidebar{
//       left: -240px !important;
//     }
// 		.left-sidebar,
// 		.sidebar-footer {
// 			left: -240px !important;
// 		}
// 		&.show-sidebar {
// 			.left-sidebar,
// 			.sidebar-footer {
// 				left: 0px;
// 			}
// 		}
// }
.sidebar-wrapper-open{
	margin-left: 240px !important;
}
.topbar-wrapper-open{
	padding-top: 100px !important;
}
.sideBarProfileImage{
	width: 30px;
	height: 30px;
	background-color: white;
	display: inline-block;
}
.sidebar-btn{
	padding: 20px 0;
}
.sidebar-footer-k {
	display: none !important;
}