.icon-color:nth-of-type(1) {
	svg {
		color: #019cd4;
	}
}

.icon-color:nth-of-type(2) {
	svg {
		color: #00d6d4;
	}
}

.icon-color:nth-of-type(3) {
	svg {
		color: #723082;
	}
}

.icon-color:nth-of-type(4) {
	svg {
		color: #e18147;
	}
}

.icon-color:nth-of-type(5) {
	svg {
		color: #ffd285;
	}
}

.icon-color:nth-of-type(6) {
	svg {
		color: #4a1a70;
	}
}

.icon-color:nth-of-type(7) {
	svg {
		color: #f33259;
	}
}

.icon-color:nth-of-type(8) {
	svg {
		color: #398bf7;
	}
}