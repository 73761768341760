.form-group {
  position: relative;

  span {
    right: 8px;
    bottom: 3px;
    font-size: .7em;
    position: absolute;
  }

  label {
    display: block;
    span {
      left: 10px;
      color: #ef5350;
      font-size: 1em;
      top: 0 !important;
      position: relative;
    }
  }

  &.is-invalid {
    label, span {
      color: #ef5350;
    }
  }

  &.has-counter .form-control {
    padding-right: 50px;
  }

  &.has-help {
    span {
      top: 50px;
    }
  }

  &._textarea {

    span {
      bottom: 10px;
    }

    &.has-counter .form-control {
      padding-right: 70px;
    }

    .form-control {
      min-height: 75px;
    }
  }

  p.form-control {
    border: none;
    display: block;
    height: auto;
    margin-bottom: 0;
    background-image: none;
  }

  textarea.form-control.is-invalid {
    background-image: none;
    background-position: center bottom, center calc(100% - 0px) !important;
  }
  
  .selected-items {
    width: auto;
    color: white;
    padding: 8px 16px;
    font-weight: bold;
    display: inline-block;
    border-radius: 8px 16px;
    background-color: #707cd2;
  }
  input::placeholder {
    color: #99abb4;
  }
}

.resizable-text {
  /*resize: vertical;*/
  /*overflow: auto;*/
  height: 50vh !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #67757c;
  font-weight: 300;
}