.register-modal-overlay {
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, .3);
  z-index: 11;

  .ReactModal__Content.ReactModal__Content--after-open {
    width: 80vw;
    height: 80vh;
    max-width: 420px;
    max-height: 620px;
    position: relative;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border: none !important;
    padding: 0 !important;
  }
}

  .register__text {
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #00d7d7;

    h1, p {
      color: white;
      font-weight: bold;
    }

    p {
      max-width: 90%;
    }
  }

  .register__close {
    position: absolute;
    cursor: pointer;
    color: white;
    right: 20px;
    top: 20px;
  }

  .register__form {
    padding: 40px;

    .btn-info {
      position: absolute;
      width: 50%;
      transform: translateX(50%);
      background: #00d7d7 !important;
      border: 1px solid #00d7d7 !important;
      left: 0;
      bottom: 20px;
    }

    .terms-container {
      position: absolute;
      bottom: 10px;
    }

    p {
      float: left;
      font-size: 12px;

      a {
        margin-left: 8px;
        font-weight: bold;
        color: #00d7d7 !important;
      }
    }
  }

.swal2-confirm{
  background-color: #00d7d7 !important;
  border-left-color: #00d7d7 !important;
  border-right-color: #00d7d7 !important;
}

.form-group.text-center {
  position: static !important;
}

@media only screen and (max-height: 640px), (max-width: 420px) {
  .register__text {
    padding: 15px;

    h1 {
      font-size: 25px;
    }

    p {
      max-width: 80%;
    }
  }

  .register__form {
    padding: 20px 30px;

    .terms-container {
      left: 20px;
      right: 20px;
    }
  }


  .register__close {
    right: 10px;
    top: 10px;
  }

}