.topbar .topsidebar{
    height: 50px;
}
.topsidebar{
    z-index: 49 !important;
    margin-top: 55px;
    .selected-tab{
        background-color: #ff5050 !important;
        a{
            color: #fff !important; 
        }
    }
    .nav-link{
        line-height: 44px !important;
        i{
            margin-right: 0.25rem;
        }
    }
}
.topsidebar-light{
    background-color: --light;
    color: --dark;
}
.topsidebar-dark{
    background-color: #20222a;
    color: --light;
    .nav-link:hover {
        color: #fff !important;
    }
}
