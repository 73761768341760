/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
@import '../variable';

/*******************
Login register and recover password Page
******************/
.login-register{
  background-size: cover;
  background-repeat: no-repeat;
  height:100%;
  width: 100%;
  padding:10% 0;
  position:fixed;
}

.join-box {
  width: 30vw;
  margin: 0 auto;
}

.login-box{
  width:400px;
  margin:0 auto;

  .footer{
   width:100%;
   left:0px;
   right:0px;
  }
  .social {
   display:block;
   margin-bottom:30px;
  }
}
#recoverform{
   display:none;
}
.login-sidebar {
   padding: 0px;

   margin-top: 0px;
   .login-box{
      right: 0px;
      position: absolute;
      height: 100%;
   }

}

.simple-link {
  color: #67757c;
  text-decoration: underline;
}

.simple-link:hover {
  color: #212529;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .login-register {
    background-repeat: inherit;
    background-position: center center;
  }
}

@media only screen and (min-width: 1280px) {
  .login-box{
    width: 30vw;
  }
}