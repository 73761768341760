/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, September 2019
 */

.buttons-carousel {
	position: relative;
}

.button-prev {
	cursor: pointer;
	left: -1%;
	position: absolute;
	top: 65%;
	color: #33CCCC;
}

.button-next {
	cursor: pointer;
	position: absolute;
	right: -1%;
	top: 65%;
	color: #33CCCC;
}

@media only screen and (max-width: 768px) {
  .button-next, .button-prev {
  	display: none;
  }
}