/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Contact app Page
******************/
/*left-aside-column*/
.contact-page-aside {
  position: relative; }

.left-aside {
  position: absolute;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  padding: 20px;
  width: 250px;
  height: 100%; }

.right-aside {
  padding: 20px;
  margin-left: 250px; }

.contact-list td {
  vertical-align: middle;
  padding: 25px 10px; }
  .contact-list td img {
    width: 30px; }

.list-style-none {
  margin: 0px;
  padding: 0px; }
  .list-style-none li {
    list-style: none;
    margin: 0px; }
    .list-style-none li.box-label a {
      font-weight: 500; }
    .list-style-none li.divider {
      margin: 10px 0;
      height: 1px;
      background: rgba(120, 130, 140, 0.13); }
    .list-style-none li a {
      padding: 15px 10px;
      display: block;
      color: #67757c; }
      .list-style-none li a:hover {
        color: #398bf7; }
      .list-style-none li a span {
        float: right; }

.social-profile {
  text-align: center;
  background: rgba(7, 10, 43, 0.8); }

.profile-tab li a.nav-link,
.customtab li a.nav-link {
  border: 0px;
  padding: 15px 20px;
  color: #67757c; }
  .profile-tab li a.nav-link.active,
  .customtab li a.nav-link.active {
    border-bottom: 2px solid #398bf7;
    color: #398bf7; }
  .profile-tab li a.nav-link:hover,
  .customtab li a.nav-link:hover {
    color: #398bf7; }
