.komunidad-color {
	background-color: #707cd2 !important;
	border: 1px solid #707cd2 !important;
}
.komunidad-logo {
	position:absolute;
  bottom:5px;
  right:10px;
  width: 70px;
  height: 70px;
}
.password_section {
	margin: 20px 0 0 20px;
}
.pv_content{
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 800px;
}
.pv_title{
	font-size: 40px;
	font-weight: bold;
	text-align: center;
	color: #434142;
}
.pv_subtitle{
	font-size: 24px;
	font-weight: bold;
	text-align: center;
}
.pv_form{
	margin-top: 10px;
}
.pv_form_title{
	text-align: center;
	margin-top: 70px;
	margin-bottom: 20px;
}
.pv_input{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 90px;
	justify-content: space-between;
}
.pv_input input{
	width: 50%;
	font-size: 20px;
	padding-left: 10px;
	padding-right: 10px;
}
.pv_input button{
	width: 50%;
	/* height: 30px; */
}
.button{
	background-color: black;
	color: white;
	border: none;
	height:30px;
	font-size: 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: calc( 50% + 26px);
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	cursor: pointer;
}
.button:hover{
	background-color: #2a2424;
}
.legend{
	text-align: center;
	font-size: 13px;
	margin-top: 12px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.pvJustifyText{
	text-align: justify;
}
.pv_wrapper{
	width:60%;
	margin-left: auto;
	margin-right: auto;
}
@media only screen and (max-width: 899px) {
	.pv_wrapper{
		width:80%;
	}
}

@media only screen and (max-width: 512px) {
	.login-box {
		width: calc(100% - 10px) !important;
	}
	.komunidad-logo {
	  width: 45px;
	  height: 45px;
	}
	.pv_title{
		font-size: 32px;
	}
	.pv_subtitle{
		font-size: 18px;
	}
	.pv_wrapper{
		width:100%;
	}
}
