.eb-event-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}
.eb-event-row:hover{
  background-color: #f3f1f1;
}
.eb-date-container{
  display: flex;
  flex-direction: column;
  min-width: 50px;
  align-items: center;
  justify-content: center
}
.eb-date-month{
  color:#f05537;
  font-weight: bold;
  font-size: 12px;
}
.eb-date-day{
  color:#6f7287;
  font-weight: bold;
}
.eb-event-description{
  display: flex;
  flex:1;
  flex-direction: column;
}
.eb-event-status{
}
.eb-event-image{
  background-position: center center;
  background-repeat: no-repeat;
}
.eb-loader-container{
  display: flex;
  flex:1;
  min-height: 70vh;
  justify-content: center;
  align-items: center;
}
.eb-back{
  cursor: pointer;
  flex: 1;
  width: 30px;
}
