.KEI_wrapper{
  display: flex;
  flex-direction: row;
  min-height: 33px;
  min-width: 200px;
  align-items: flex-start;
}
.KEI_label{
  /* min-width: 70px; */
  min-width: 95px;
  font-weight: bold;
}
.KEI_input{
  height: 25px;
  flex-grow: 1;
  max-width: 100%;
  border:none;
}
.profileHoverLabel{
  width: 100%;
}
.KEI_required{
  color:red;
  width: 10px;
  text-align: center;
  height: 33px;
}
.KEI_textArea{
  min-width: 400px;
  min-height: 150px;
  flex-grow: 1;
  border:none;
}
.KEIWrapperElement{
  display: flex;
  flex-direction: column;
  /* background-color: pink; */
  border: solid 1px #ccc;
}
.KEICounter{
  font-size: smaller;
  color: #b1b1b1;
  align-self: flex-end;
}
