/* right bar */
.panel-container {
  height: 100%;
  width: 100%;
  background-color: white;
}
.close-btn{
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: flex-end;
}
