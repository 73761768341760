.resource-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
}

.resource-information {
  text-align: center;
  display: inline-flex;
  svg {
    text-align: center;
  }
}

.resource-link {
  color: #707cd2 !important;
}

.resource-col {
  flex: inherit !important;
  max-width: none !important;
}

@media(max-width: 700px) {
  .folder-style {
    margin: 0 auto;
  }
}