.logout_link{
  cursor:pointer;
}

.header-bg-k {
	background-image: url("../../vendor/images/app_logo_2021.png");
	width: 159px;
	height: 100%;
	background-repeat: no-repeat;
	background-position-y: center;
}

.header-bg-ksq {
	background-image: url("../../vendor/images/app_logo_sq_2021.png");
	width: 56px;
	height: 100%;
	background-repeat: no-repeat;
	background-position-y: center;
	margin: 0;
}

.footer-link{
	margin-right: 7px;
	margin-left: 7px;
}

.first-footer-link{
	margin-right: 7px;
}

.header-bg-k {
	background-image: url("../../vendor/images/app_logo_2021.png");
	width: 159px;
	height: 100%;
	background-repeat: no-repeat;
	background-position-y: center;
}

.header-bg-ksq {
	background-image: url("../../vendor/images/app_logo_sq_2021.png");
	width: 56px;
	height: 100%;
	background-repeat: no-repeat;
	background-position-y: center;
	margin: 0;
}

.footer_4{
	padding-top: 10px;
}
.footer-line{
	height: 1px;
	background-color: #ebeaed;
	margin-top: 10px;
	margin-bottom: 10px;
}
