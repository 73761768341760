/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
textarea
******************/
textarea {
  resize: none; }

/*******************
Form-control
******************/
.form-control {
  color: #67757c;
  min-height: 38px;
  display: initial; }

.form-control-sm {
  min-height: 20px; }

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.7; }

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none; }

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #06d79c; }

form label {
  font-weight: 400; }

.form-group {
  margin-bottom: 25px; }

.form-horizontal label {
  margin-bottom: 0px; }

.form-control-static {
  padding-top: 0px; }

.form-bordered .form-group {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding-bottom: 20px; }

/*******************
Form Dropzone
******************/
.dropzone {
  border: 1px dashed #b1b8bb; }
  .dropzone .dz-message {
    padding: 5% 0;
    margin: 0px; }

/*******************
Form Pickers
******************/
.asColorPicker-dropdown {
  max-width: 260px; }

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: -35px;
  height: 38px;
  width: 37px;
  border: 0; }

.asColorPicker-clear {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-decoration: none; }

table th {
  font-weight: 400; }

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #398bf7; }

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background: #398bf7;
  color: #ffffff; }

.datepicker td,
.datepicker th {
  padding: 5px 10px; }

/*******************
Form summernote
******************/
.note-popover,
.note-icon-caret {
  display: none; }

.note-editor.note-frame {
  border: 1px solid #b1b8bb; }
  .note-editor.note-frame .panel-heading {
    padding: 6px 10px 10px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13); }

.label {
  display: inline-block; }

/*==============================================================
Form addons page
============================================================== */
/*Bootstrap select*/
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select .dropdown-menu li a {
  display: block;
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #67757c;
  white-space: nowrap; }
  .bootstrap-select .dropdown-menu li a:hover, .bootstrap-select .dropdown-menu li a:focus {
    color: #398bf7;
    background: #f7fafc; }

.bootstrap-select .show > .dropdown-menu {
  display: block; }

.select2-container--default .select2-selection--single {
  border-color: #b1b8bb;
  height: 38px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 33px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  float: right;
  color: #ffffff;
  margin-right: 0px;
  margin-left: 4px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #398bf7;
  color: #ffffff;
  border-color: #398bf7; }

.input-form .btn {
  padding: 8px 12px; }

/*==============================================================
Form Material page
 ============================================================== */
/*Material inputs*/
.form-material .form-group {
  overflow: hidden; }

.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s; }

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#f7fafc, #f7fafc);
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none; }

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  transition-duration: 0.3s; }

.form-control-line .form-group {
  overflow: hidden; }

.form-control-line .form-control {
  border: 0px;
  border-radius: 0px;
  padding-left: 0px;
  border-bottom: 1px solid #f6f9ff; }
  .form-control-line .form-control:focus {
    border-bottom: 1px solid #398bf7; }

/*******************
Form validation error
******************/
.error .help-block {
  color: #fb3a3a; }

/*******************
File Upload
******************/
.fileupload {
  overflow: hidden;
  position: relative; }
  .fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }

/*******************
This is for the checkbox radio button and switch
******************/
/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  /* webkit (konqueror) browsers */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease; }

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%; }

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 1px solid #b1b8bb; }

[type="radio"]:not(:checked) + label:after {
  z-index: -1;
  -webkit-transform: scale(0);
  transform: scale(0); }

/* Checked styles */
[type="radio"]:checked + label:before {
  border: 2px solid transparent;
  animation: ripple 0.2s linear forwards; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #26a69a; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #26a69a;
  z-index: 0; }

[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02); }

/* Radio With gap */
[type="radio"].with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5); }

/* Focused styles */
[type="radio"].tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  animation: ripple 0.2s linear forwards; }

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26);
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26); }

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  animation: ripple 0.2s linear forwards; }

[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD; }

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left; }

form p:last-child {
  margin-bottom: 0; }

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="checkbox"] {
  /* checkbox aspect */ }

[type="checkbox"] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */ }

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 1px solid #b1b8bb;
  border-radius: 1px;
  margin-top: 2px;
  transition: .2s; }

[type="checkbox"]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type="checkbox"]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26); }

[type="checkbox"].tabbed:focus + label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26); }

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent; }

[type="checkbox"].filled-in + label:after {
  border-radius: 2px; }

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1; }

[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%; }

[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #b1b8bb;
  top: 0px;
  z-index: 0; }

[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0; }

[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a; }

[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent; }

[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD; }

[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent; }

[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD; }

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none; }

.switch label {
  cursor: pointer; }

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0; }

.switch label input[type=checkbox]:checked + .lever {
  background-color: #a7b1fd !important; }

.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #707cd2;
  left: 24px; }

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px; }

.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: #F1F1F1;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -3px;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease; }

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1); }

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08); }

.switch input[type=checkbox][disabled] + .lever {
  cursor: default; }

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: #BDBDBD; }

.scale-up {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
  display: inline-block;
  transform-origin: right 0px; }

.scale-up-left {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
  display: inline-block;
  transform-origin: left 0px; }

.show > .scale-up {
  transform: scale(1);
  transform-origin: right 0px; }

.show > .scale-up-left {
  transform: scale(1);
  transform-origin: left 0px; }

/*panels*/
.card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.well, pre {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }

.page-titles .justify-content-end:last-child .d-flex {
  margin-right: 10px; }

.btn-circle.right-side-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 25px;
  z-index: 10; }

/*Radio button*/
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px transparent; }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1); }
  100% {
    box-shadow: 0px 0px 0px 15px transparent; } }

/*Floating label*/
/*Bootstrap Select*/
.bootstrap-select.btn-group .dropdown-menu {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }

.demo-checkbox label, .demo-radio-button label {
  min-width: 200px;
  margin-bottom: 20px; }

.demo-swtich .demo-switch-title, .demo-swtich .switch {
  width: 150px;
  margin-bottom: 10px;
  display: inline-block; }

[type="checkbox"] + label {
  padding-left: 26px;
  height: 25px;
  line-height: 21px;
  font-weight: normal; }

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -2px;
  width: 11px;
  height: 19px; }

[type="checkbox"]:checked.chk-col-red + label:before {
  border-right: 2px solid #fb3a3a;
  border-bottom: 2px solid #fb3a3a; }

[type="checkbox"]:checked.chk-col-pink + label:before {
  border-right: 2px solid #E91E63;
  border-bottom: 2px solid #E91E63; }

[type="checkbox"]:checked.chk-col-purple + label:before {
  border-right: 2px solid #7460ee;
  border-bottom: 2px solid #7460ee; }

[type="checkbox"]:checked.chk-col-deep-purple + label:before {
  border-right: 2px solid #673AB7;
  border-bottom: 2px solid #673AB7; }

[type="checkbox"]:checked.chk-col-indigo + label:before {
  border-right: 2px solid #3F51B5;
  border-bottom: 2px solid #3F51B5; }

[type="checkbox"]:checked.chk-col-blue + label:before {
  border-right: 2px solid #02bec9;
  border-bottom: 2px solid #02bec9; }

[type="checkbox"]:checked.chk-col-light-blue + label:before {
  border-right: 2px solid #03A9F4;
  border-bottom: 2px solid #03A9F4; }

[type="checkbox"]:checked.chk-col-cyan + label:before {
  border-right: 2px solid #00BCD4;
  border-bottom: 2px solid #00BCD4; }

[type="checkbox"]:checked.chk-col-teal + label:before {
  border-right: 2px solid #009688;
  border-bottom: 2px solid #009688; }

[type="checkbox"]:checked.chk-col-green + label:before {
  border-right: 2px solid #26c6da;
  border-bottom: 2px solid #26c6da; }

[type="checkbox"]:checked.chk-col-light-green + label:before {
  border-right: 2px solid #8BC34A;
  border-bottom: 2px solid #8BC34A; }

[type="checkbox"]:checked.chk-col-lime + label:before {
  border-right: 2px solid #CDDC39;
  border-bottom: 2px solid #CDDC39; }

[type="checkbox"]:checked.chk-col-yellow + label:before {
  border-right: 2px solid #ffe821;
  border-bottom: 2px solid #ffe821; }

[type="checkbox"]:checked.chk-col-amber + label:before {
  border-right: 2px solid #FFC107;
  border-bottom: 2px solid #FFC107; }

[type="checkbox"]:checked.chk-col-orange + label:before {
  border-right: 2px solid #FF9800;
  border-bottom: 2px solid #FF9800; }

[type="checkbox"]:checked.chk-col-deep-orange + label:before {
  border-right: 2px solid #FF5722;
  border-bottom: 2px solid #FF5722; }

[type="checkbox"]:checked.chk-col-brown + label:before {
  border-right: 2px solid #795548;
  border-bottom: 2px solid #795548; }

[type="checkbox"]:checked.chk-col-grey + label:before {
  border-right: 2px solid #9E9E9E;
  border-bottom: 2px solid #9E9E9E; }

[type="checkbox"]:checked.chk-col-blue-grey + label:before {
  border-right: 2px solid #607D8B;
  border-bottom: 2px solid #607D8B; }

[type="checkbox"]:checked.chk-col-black + label:before {
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000; }

[type="checkbox"]:checked.chk-col-white + label:before {
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff; }

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0; }

[type="checkbox"].filled-in:checked + label:before {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important; }

[type="checkbox"].filled-in:checked.chk-col-red + label:after {
  border: 2px solid #fb3a3a;
  background-color: #fb3a3a; }

[type="checkbox"].filled-in:checked.chk-col-pink + label:after {
  border: 2px solid #E91E63;
  background-color: #E91E63; }

[type="checkbox"].filled-in:checked.chk-col-purple + label:after {
  border: 2px solid #7460ee;
  background-color: #7460ee; }

[type="checkbox"].filled-in:checked.chk-col-deep-purple + label:after {
  border: 2px solid #673AB7;
  background-color: #673AB7; }

[type="checkbox"].filled-in:checked.chk-col-indigo + label:after {
  border: 2px solid #3F51B5;
  background-color: #3F51B5; }

[type="checkbox"].filled-in:checked.chk-col-blue + label:after {
  border: 2px solid #02bec9;
  background-color: #02bec9; }

[type="checkbox"].filled-in:checked.chk-col-light-blue + label:after {
  border: 2px solid #03A9F4;
  background-color: #03A9F4; }

[type="checkbox"].filled-in:checked.chk-col-cyan + label:after {
  border: 2px solid #00BCD4;
  background-color: #00BCD4; }

[type="checkbox"].filled-in:checked.chk-col-teal + label:after {
  border: 2px solid #009688;
  background-color: #009688; }

[type="checkbox"].filled-in:checked.chk-col-green + label:after {
  border: 2px solid #26c6da;
  background-color: #26c6da; }

[type="checkbox"].filled-in:checked.chk-col-light-green + label:after {
  border: 2px solid #8BC34A;
  background-color: #8BC34A; }

[type="checkbox"].filled-in:checked.chk-col-lime + label:after {
  border: 2px solid #CDDC39;
  background-color: #CDDC39; }

[type="checkbox"].filled-in:checked.chk-col-yellow + label:after {
  border: 2px solid #ffe821;
  background-color: #ffe821; }

[type="checkbox"].filled-in:checked.chk-col-amber + label:after {
  border: 2px solid #FFC107;
  background-color: #FFC107; }

[type="checkbox"].filled-in:checked.chk-col-orange + label:after {
  border: 2px solid #FF9800;
  background-color: #FF9800; }

[type="checkbox"].filled-in:checked.chk-col-deep-orange + label:after {
  border: 2px solid #FF5722;
  background-color: #FF5722; }

[type="checkbox"].filled-in:checked.chk-col-brown + label:after {
  border: 2px solid #795548;
  background-color: #795548; }

[type="checkbox"].filled-in:checked.chk-col-grey + label:after {
  border: 2px solid #9E9E9E;
  background-color: #9E9E9E; }

[type="checkbox"].filled-in:checked.chk-col-blue-grey + label:after {
  border: 2px solid #607D8B;
  background-color: #607D8B; }

[type="checkbox"].filled-in:checked.chk-col-black + label:after {
  border: 2px solid #000000;
  background-color: #000000; }

[type="checkbox"].filled-in:checked.chk-col-white + label:after {
  border: 2px solid #ffffff;
  background-color: #ffffff; }

[type="radio"]:not(:checked) + label {
  padding-left: 26px;
  height: 25px;
  line-height: 25px;
  font-weight: normal; }

[type="radio"]:checked + label {
  padding-left: 26px;
  height: 25px;
  line-height: 25px;
  font-weight: normal; }

[type="radio"].radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border-color: #fb3a3a;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-pink:checked + label:after {
  background-color: #E91E63;
  border-color: #E91E63;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border-color: #7460ee;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-deep-purple:checked + label:after {
  background-color: #673AB7;
  border-color: #673AB7;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-indigo:checked + label:after {
  background-color: #3F51B5;
  border-color: #3F51B5;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border-color: #02bec9;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-light-blue:checked + label:after {
  background-color: #03A9F4;
  border-color: #03A9F4;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-cyan:checked + label:after {
  background-color: #00BCD4;
  border-color: #00BCD4;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-teal:checked + label:after {
  background-color: #009688;
  border-color: #009688;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-green:checked + label:after {
  background-color: #26c6da;
  border-color: #26c6da;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-light-green:checked + label:after {
  background-color: #8BC34A;
  border-color: #8BC34A;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-lime:checked + label:after {
  background-color: #CDDC39;
  border-color: #CDDC39;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border-color: #ffe821;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-amber:checked + label:after {
  background-color: #FFC107;
  border-color: #FFC107;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-orange:checked + label:after {
  background-color: #FF9800;
  border-color: #FF9800;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-deep-orange:checked + label:after {
  background-color: #FF5722;
  border-color: #FF5722;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-brown:checked + label:after {
  background-color: #795548;
  border-color: #795548;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-grey:checked + label:after {
  background-color: #9E9E9E;
  border-color: #9E9E9E;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-blue-grey:checked + label:after {
  background-color: #607D8B;
  border-color: #607D8B;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-black:checked + label:after {
  background-color: #000000;
  border-color: #000000;
  animation: ripple 0.2s linear forwards; }

[type="radio"].radio-col-white:checked + label:after {
  background-color: #ffffff;
  border-color: #ffffff;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-red:checked + label:before {
  border: 2px solid #fb3a3a;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border: 2px solid #fb3a3a;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-pink:checked + label:before {
  border: 2px solid #E91E63;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-pink:checked + label:after {
  background-color: #E91E63;
  border: 2px solid #E91E63;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-purple:checked + label:before {
  border: 2px solid #7460ee;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border: 2px solid #7460ee;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-purple:checked + label:before {
  border: 2px solid #673AB7;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-purple:checked + label:after {
  background-color: #673AB7;
  border: 2px solid #673AB7;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-indigo:checked + label:before {
  border: 2px solid #3F51B5;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-indigo:checked + label:after {
  background-color: #3F51B5;
  border: 2px solid #3F51B5;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue:checked + label:before {
  border: 2px solid #02bec9;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border: 2px solid #02bec9;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-blue:checked + label:before {
  border: 2px solid #03A9F4;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-blue:checked + label:after {
  background-color: #03A9F4;
  border: 2px solid #03A9F4;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-cyan:checked + label:before {
  border: 2px solid #00BCD4;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-cyan:checked + label:after {
  background-color: #00BCD4;
  border: 2px solid #00BCD4;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-teal:checked + label:before {
  border: 2px solid #009688;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-teal:checked + label:after {
  background-color: #009688;
  border: 2px solid #009688;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-green:checked + label:before {
  border: 2px solid #26c6da;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-green:checked + label:after {
  background-color: #26c6da;
  border: 2px solid #26c6da;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-green:checked + label:before {
  border: 2px solid #8BC34A;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-light-green:checked + label:after {
  background-color: #8BC34A;
  border: 2px solid #8BC34A;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-lime:checked + label:before {
  border: 2px solid #CDDC39;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-lime:checked + label:after {
  background-color: #CDDC39;
  border: 2px solid #CDDC39;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-yellow:checked + label:before {
  border: 2px solid #ffe821;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border: 2px solid #ffe821;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-amber:checked + label:before {
  border: 2px solid #FFC107;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-amber:checked + label:after {
  background-color: #FFC107;
  border: 2px solid #FFC107;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-orange:checked + label:before {
  border: 2px solid #FF9800;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-orange:checked + label:after {
  background-color: #FF9800;
  border: 2px solid #FF9800;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-orange:checked + label:before {
  border: 2px solid #FF5722;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-deep-orange:checked + label:after {
  background-color: #FF5722;
  border: 2px solid #FF5722;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-brown:checked + label:before {
  border: 2px solid #795548;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-brown:checked + label:after {
  background-color: #795548;
  border: 2px solid #795548;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-grey:checked + label:before {
  border: 2px solid #9E9E9E;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-grey:checked + label:after {
  background-color: #9E9E9E;
  border: 2px solid #9E9E9E;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue-grey:checked + label:before {
  border: 2px solid #607D8B;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-blue-grey:checked + label:after {
  background-color: #607D8B;
  border: 2px solid #607D8B;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-black:checked + label:before {
  border: 2px solid #000000;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-black:checked + label:after {
  background-color: #000000;
  border: 2px solid #000000;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-white:checked + label:before {
  border: 2px solid #ffffff;
  animation: ripple 0.2s linear forwards; }

[type="radio"].with-gap.radio-col-white:checked + label:after {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  animation: ripple 0.2s linear forwards; }

.switch label {
  font-weight: normal;
  font-size: 13px; }
  .switch label .lever {
    margin: 0 14px; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-red:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-red {
    background-color: rgba(251, 58, 58, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-red:after {
      background-color: #fb3a3a; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-pink:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-pink {
    background-color: rgba(233, 30, 99, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-pink:after {
      background-color: #E91E63; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-purple:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-purple {
    background-color: rgba(116, 96, 238, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-purple:after {
      background-color: #7460ee; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-deep-purple:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-deep-purple {
    background-color: rgba(103, 58, 183, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-deep-purple:after {
      background-color: #673AB7; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-indigo:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-indigo {
    background-color: rgba(63, 81, 181, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-indigo:after {
      background-color: #3F51B5; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-blue:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-blue {
    background-color: rgba(2, 190, 201, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-blue:after {
      background-color: #02bec9; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-light-blue:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-light-blue {
    background-color: rgba(3, 169, 244, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-light-blue:after {
      background-color: #03A9F4; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-cyan:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-cyan {
    background-color: rgba(0, 188, 212, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-cyan:after {
      background-color: #00BCD4; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-teal:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-teal {
    background-color: rgba(0, 150, 136, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-teal:after {
      background-color: #009688; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-green:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-green {
    background-color: rgba(38, 198, 218, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-green:after {
      background-color: #26c6da; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-light-green:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-light-green {
    background-color: rgba(139, 195, 74, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-light-green:after {
      background-color: #8BC34A; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-lime:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-lime {
    background-color: rgba(205, 220, 57, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-lime:after {
      background-color: #CDDC39; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-yellow:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-yellow {
    background-color: rgba(255, 232, 33, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-yellow:after {
      background-color: #ffe821; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-amber:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-amber {
    background-color: rgba(255, 193, 7, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-amber:after {
      background-color: #FFC107; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-orange:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-orange {
    background-color: rgba(255, 152, 0, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-orange:after {
      background-color: #FF9800; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-deep-orange:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-deep-orange {
    background-color: rgba(255, 87, 34, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-deep-orange:after {
      background-color: #FF5722; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-brown:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-brown {
    background-color: rgba(121, 85, 72, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-brown:after {
      background-color: #795548; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-grey:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-grey {
    background-color: rgba(158, 158, 158, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-grey:after {
      background-color: #9E9E9E; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-blue-grey:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-blue-grey {
    background-color: rgba(96, 125, 139, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-blue-grey:after {
      background-color: #607D8B; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-black:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-black {
    background-color: rgba(0, 0, 0, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-black:after {
      background-color: #000000; }
  .switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-white:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1); }
  .switch label input[type=checkbox]:checked + .lever.switch-col-white {
    background-color: rgba(255, 255, 255, 0.5); }
    .switch label input[type=checkbox]:checked + .lever.switch-col-white:after {
      background-color: #ffffff; }

/*Form Validation*/
.help-block ul {
  padding: 0px;
  margin: 0px; }
  .help-block ul li {
    list-style: none; }

.error .form-control {
  border-color: #ef5350; }

.validate .form-control {
  border-color: #06d79c; }

/*******************
/*Image Cropper Page
******************/
.docs-buttons .btn,
.docs-toggles .btn-group,
.docs-data .input-group {
  margin-bottom: 5px; }

/*******************
/*Form Typahead Page
******************/
.twitter-typeahead {
  width: 100%; }

.twitter-typeahead .tt-menu {
  width: 100%;
  background: #ffffff;
  border: 1px solid #99abb4;
  border-radius: 5px;
  padding: .75rem 0; }

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: .25rem .75rem;
  cursor: pointer; }

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #745af2;
  color: #ffffff; }

.twitter-typeahead .empty-message {
  padding: 5px 10px;
  text-align: center; }

.twitter-typeahead .rtl-typeahead .tt-menu {
  text-align: right; }

.twitter-typeahead .league-name {
  margin: 0 10px 5px;
  padding: 7px 5px 10px;
  border-bottom: 1px solid #99abb4; }

.scrollable-dropdown .twitter-typeahead .tt-menu {
  max-height: 80px;
  overflow-y: auto; }

.bootstrap-maxlength {
  margin-top: .5rem; }
