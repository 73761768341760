/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, August 2019
 */

.nav-header {
	width: 100vw !important;
	z-index: 11;
	top: 0 !important;
	left: 0 !important;
	position: fixed !important;
	transition: (padding .5s ease);
}

.on {
	padding: 0 !important;
	transition: (padding .5s ease);
}

.landing_container {
	margin-top: 80px !important;
}

.not-collapse {
  display: block !important;
}

.navbar-expand-md .nav-toggler {
	display: none;
}

.navbar-complete {
	position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.navbar-navigation{
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media only screen and (max-width: 1130px) {
	.not-collapse {
    display: none !important;
	}

	.navbar-expand-md .nav-col {
		display: block !important;
    flex-basis: auto;
	}

	.navbar-expand-md .nav-toggler {
		display: inline-block;
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
	}

	.topheader .style-nav {
    padding: 20px;
    margin-top: 15px;
    border: 1px solid rgba(120, 130, 140, 0.13);
	}

	.navbar-navigation {
    flex-direction: column !important;
    padding-left: 0 !important;
    margin-bottom: 0;
	}

	.nav-col {
    flex-basis: 100% !important;
    flex-grow: 1 !important;
    align-items: center !important;
	}

}