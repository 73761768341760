/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

@import '../variable';









/* perfect-scrollbar v0.7.1 */

.ps {
   -ms-touch-action: auto;
   touch-action: auto;
   overflow: hidden !important;
   -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
   .ps {
      overflow: auto !important;
   }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
   .ps {
      overflow: auto !important;
   }
}

.ps.ps--active-x>.ps__scrollbar-x-rail,
.ps.ps--active-y>.ps__scrollbar-y-rail {
   display: block;
   background-color: transparent;
}

.ps.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail {
   background-color: #eee;
   opacity: 0.9;
}

.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
   background-color: transparent;
   width: 8px;
}

.ps>.ps__scrollbar-x-rail {
   display: none;
   position: absolute;
   /* please don't change 'position' */
   opacity: 0;
   bottom: 0px;
   /* there must be 'bottom' for ps__scrollbar-x-rail */
   height: 6px;
}

.ps>.ps__scrollbar-x-rail>.ps__scrollbar-x {
   position: absolute;
   /* please don't change 'position' */
   background-color: rgba(255, 255, 255, 0.2);
   -webkit-border-radius: 6px;
   -moz-border-radius: 6px;
   border-radius: 6px;
   bottom: 2px;
   /* there must be 'bottom' for ps__scrollbar-x */
   height: 6px;
}

.ps>.ps__scrollbar-x-rail:hover>.ps__scrollbar-x,
.ps>.ps__scrollbar-x-rail:active>.ps__scrollbar-x {
   height: 6px;
}

.ps>.ps__scrollbar-y-rail {
   display: none;
   position: absolute;
   /* please don't change 'position' */
   opacity: 0;
   right: 0;
   /* there must be 'right' for ps__scrollbar-y-rail */
   width: 6px;
}

.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
   position: absolute;
   /* please don't change 'position' */
   background-color: rgba(0, 0, 0, 0.2);
   -webkit-border-radius: 6px;
   -moz-border-radius: 6px;
   border-radius: 6px;
   right: 2px;
   /* there must be 'right' for ps__scrollbar-y */
   width: 6px;
}

.ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:active>.ps__scrollbar-y {
   width: 6px;
}

.ps:hover.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail {
   background-color: rgba(0, 0, 0, 0.2);
   opacity: 0.9;
}

.ps:hover.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail>.ps__scrollbar-x {
   background-color: rgba(0, 0, 0, 0.2);
   height: 6px;
}

.ps:hover.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
   background-color: rgba(0, 0, 0, 0.2);
   width: 6px;
}

.ps:hover>.ps__scrollbar-x-rail,
.ps:hover>.ps__scrollbar-y-rail {
   opacity: 0.6;
}

.ps:hover>.ps__scrollbar-x-rail:hover>.ps__scrollbar-x {
   background-color: rgba(0, 0, 0, 0.2);
}

.ps:hover>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
   background-color: rgba(0, 0, 0, 0.2);
}

#slimtest3.ps .ps__scrollbar-y-rail {
   left: 3px;
   right: auto;
}

#slimtest4.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
   background-color: $info;
}

#slimtest4.ps:hover.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
   background-color: $info;
}

#slimtest4.ps:hover>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
   background-color: $info;
}