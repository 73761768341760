.imagePickerComponent{
  display: flex;
  flex-direction: column;
  background: transparent;
}
.imagePickerWrapper{
  height: 100%;
  width: 100%;
  background-color: #000000e6;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.imagePickerCancelBtn{
  position: absolute;
  top:20px;
  right: 30px;
  color:white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.imagePickerSaveBtn{
  cursor: pointer;
  margin-top: 20px;
  background-color: black;
  padding: 5px;
  padding-right: 5px;
  padding-left: 5px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
.imagePickerWrapperInput{
  cursor:pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imagePickerWrapperInput:hover{
  /* background-color: #00000055; */
  color:white;
}
.imagePickerWrapperBtns{
  display: flex;
  flex-direction: column;
  width:50%;
  align-items: flex-end;
  margin-top: 20px;
}
.imagePickerWrapperRotateBtn{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.imagePickerRotateBtn{
  font-size: 32px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #383838;
  cursor:pointer;
}
.reactCrop{
  max-width: 50%;
}
.marginLeft20{
  margin-left: 20px;
}
.imagePickerInput{
  display: none;
}
.imagePickerImage{
  display: flex;
  width: 100%;
}
@media only screen and (min-width: 801px) and (max-width: 1024 px){
  .reactCrop{
    max-width: 75%;
  }
  .imagePickerWrapperBtns{
    width:75%;
  }
}
@media only screen and (max-width: 800px) {
  .reactCrop{
    max-width: 100%
  }
  .imagePickerWrapperBtns{
    width:100%;
    padding-right: 50px;
  }
}

.ReactCrop__image{
  max-height: 400px;
}
