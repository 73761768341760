/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

.kuMainWrapper{
  display:flex;
  flex-direction: column;
}
.kuRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-bottom: solid 0.05px #ccc;
  padding: 10px;
  cursor: pointer;
}
.kuRow:hover{
  background-color: #e9e9e9;
}

.manager-item .kuRow{
  cursor: default;
  border-bottom: 1px solid #dee2e6;
  padding: 12px;
  height: 85px;
}

.manager-item .kuRow:hover {
  background-color: white;
}

.kuList{
  padding: 0px;
  margin: 0px;
}
.kuImage{
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: solid 0.25px #f6f6f6;
}
.kuName{
  padding-left: 10px;
}
.kuBCGray{
  background-color: #e9e9e9;
}
.kuWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.kuEmail{
  font-size: small;
}
.kuEmptyLabel{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #e9e9e9;
}
