.email-size{
  min-height: 200px !important;
  .RichTextEditor__editor___1QqIU{
    font-family: Arial, Helvetica, sans-serif;
  }
}
.skylight-dialog {
  padding: 0 !important;
}
.modal__body__height{
  height: 420px;
}
.wrapper__kUser__list{
  // background-color:pink;
  max-height:280px;
  overflow: auto;
}
.wrapper__kCompany__list{
  max-height:400px;
  overflow: auto;
}
.modal__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;

  span {
    font-size: 18px;
    text-align: left !important;
  }
}
.wrapper__kAssignModalCompany{
  height:450px;
}
.wrapper__kAMCompanyList{
 height: 250px;
 overflow: auto;
 margin-top:-15px;
 /*margin is a work around*/
}
.wrapper__kAMCompanyWidget{
  height: 100px;
  margin-top: 30px;
  overflow: auto;
}

.modal__btn__back{
  width: 20px;
  height: 36px;
  padding-right: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal__body {
  padding: 20px;

  input {
    border-radius: 0;
    border-bottom: 1px solid #ccc;

    &:focus {
      outline: none;
      border-color: #06d79c;
    }

    &.error {
      border-color: red !important;
    }
  }
}

.modal__footer {
  border-top: 1px solid #e9ecef;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}

.KAPMImage {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.KAPMName {
  margin: 20px 0;
}

.KAPMUser {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.KAPMMessage{
  color:#398bf7;
}
.KAPMBold{
  font-weight: bold !important;
}
.KAPMImageSquare{
  border-radius: 10%;
  width: 120px;
  height: 120px;
}
@media (max-width: 767px) {
  .wrapper__kUser__list{
    max-height:210px;
  }
  .modal__body__height{
    height: 400px;
  }
  .wrapper__kCompany__list{
    max-height:380px;

  }
}
