
.company-widget-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.company-widget{
  display: flex;
  flex-direction: row;
  flex:1;
  flex-wrap: wrap;
}

.c-w-element{
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  background-color: #fafafa;
  margin: 5px 10px 5px 0px;
  /*top right bottom left */
}
.c-w-remove-btn{
  margin: 4px 10px 0px 0px;
  align-self: center;
  color:#c2c2c2;
  cursor:pointer;
}
.p-l-5{
  padding-left: 5px;
}
.c-w-image{
  width: 30px;
  height: 30px;
  border-radius: 3px;
}
