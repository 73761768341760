/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

@import '../variable';
/*==============================================================
 For all pages
 ============================================================== */

#main-wrapper {
  width: 100%;
  overflow: hidden;
}

.boxed #main-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  .sidebar-footer {
    position: absolute;
  }
  .topbar{
    max-width: 1300px;
  }
  .footer {
    display: none;
  }
}

.page-wrapper {
  background: $bodycolor;
  padding-bottom: 60px;
  position: relative;
}

.container-fluid {
  padding: 25px 15px 0 15px;
}

.card {
  margin-bottom: 30px;
  .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: $muted;
  }
  .card-title {
    position: relative;
  }
}

/*******************
 Topbar
*******************/

.fix-header {
  .topbar {
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  }
  .page-wrapper {
    padding-top: 70px;
  }
  .right-side-panel {
    top: 148px;
    height: calc(100% - 148px);
  }
}

.topbar {
  position: relative;
  z-index: 50;
  .navbar-collapse {
    padding: 0 10px;
  }
  .top-navbar {
    min-height: 70px;
    padding: 0px;
    .dropdown-toggle::after {
      display: none;
    }
    .navbar-header {
      line-height: 55px;
      padding-left: 10px;
      .navbar-brand {
        margin-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        .light-logo {
          display: none;
        }
        b {
          line-height: 70px;
          display: inline-block;
        }
      }
    }
    .navbar-nav>.nav-item>.nav-link {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 17px;
      line-height: 54px;
    }
    .navbar-nav>.nav-item>span {
      line-height: 65px;
      font-size: 24px;
      font-weight: 500;
      color: $white;
      padding: 0 10px;
    }
    .navbar-nav>.nav-item.show {
      background: $dark-transparent;
    }
  }
  .profile-pic {
    width: 30px;
    border-radius: 100%;
  }
  .dropdown-menu {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    border-color: $border;
    .dropdown-item {
      padding: 7px 1.5rem;
    }
  }
  ul.dropdown-user {
    padding: 0px;
    min-width: 270px;
    li {
      list-style: none;
      padding: 0px;
      margin: 0px;
      &.divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: $border;
      }
      .dw-user-box {
        padding: 10px 15px;
        .u-img {
          width: 70px;
          display: inline-block;
          vertical-align: top;

          img {
            width: 100%;
            border-radius: 5px;
            height: 70px;
            display: block;
            background-color: white;
          }
        }
        .u-text {
          display: inline-block;
          padding-left: 10px;
          h4 {
            margin: 0px;
            font-size: 15px;
          }
          p {
            margin-bottom: 2px;
            font-size: 12px;
          }
          .btn {
            color: $white;
            padding: 5px 10px;
            display: inline-block;
            &:hover {
              background: $danger-dark;
            }
          }
        }
      }
      a {
        padding: 9px 15px;
        display: block;
        color: $bodytext;
        &:hover {
          background: $light;
          color: $themecolor;
          text-decoration: none;
        }
      }
    }
  }
}

.search-box {
  .app-search {
    position: absolute;
    margin: 0px;
    display: block;
    z-index: 110;
    width: 100%;
    top: -1px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0px;
    input {
      width: 100.5%;
      padding: 25px 40px 25px 20px;
      border-radius: 0px;
      font-size: 17px;
      transition: 0.5s ease-in;
      height: 73px;
      &:focus {
        border-color: $white;
      }
    }
    .srh-btn {
      position: absolute;
      top: 23px;
      cursor: pointer;
      background: $white;
      width: 15px;
      height: 15px;
      right: 20px;
      font-size: 14px;
    }
  }
}

.mini-sidebar .top-navbar {
  .navbar-header {
    width: 70px;
    text-align: center;
    flex-shrink: 0;
    padding-left: 0px;
  }
}

.logo-center {
  .top-navbar {
    .navbar-header {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

/*******************
Notify
*******************/

.notify {
  position: relative;
  top: -28px;
  right: -7px;
  .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid $danger;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: $danger;
    position: absolute;
    right: 6px;
    top: -10px;
  }
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0;
  }
  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0.0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}


/*******************
 Horizontal-navabar
*******************/

.hdr-nav-bar {
  .navbar {
    background: $white;
    border-top: 1px solid $border;
    padding: 0 15px;
    .navbar-nav {
      >li {
        padding: 0 10px;
        >a {
          padding: 25px 5px;
          transition: 0.2s ease-in;
          border-bottom: 2px solid transparent;
        }
        &.active>a,
        &:hover>a,
        >a:focus {
          border-color: $themecolor;
        }
      }
      .dropdown-toggle:after {
        display: none;
      }
    }
    .navbar-brand {
      padding: 20px 5px;
    }
    .navbar-toggler {
      border: 0px;
      cursor: pointer;
    }
    .custom-select {
      border-radius: 60px;
      font-size: 14px;
      padding: .375rem 40px .375rem 18px;
    }
    .call-to-act {
      padding: 15px 0;
    }
  }
}
/*******************
 Breadcrumb and page title
*******************/

.page-titles {
  background: $bodycolor;
  margin-bottom: 20px;
  padding: 0px;
  h3 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .breadcrumb {
    padding: 0px;
    margin-bottom:0px;
    background: transparent;
    font-size: 14px;
    li {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .breadcrumb-item+.breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: $light-text;
      font-size: 11px;
    }
    .breadcrumb-item.active {
      color: $muted;
    }
  }
}

.p-relative {
  position: relative;
}

.lstick {
  width: 2px;
  background: $themecolor;
  height: 30px;
  margin-left: -20px;
  margin-right: 18px;
  display: inline-block;
  vertical-align: middle;
}

/*******************
 Right side toggle
*******************/

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.right-side-toggle {
  position: relative;
}

.right-side-toggle i {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  transition-property: transform;
  transition-duration: 1s;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  top: 18px;
  left: 18px;
}

.right-sidebar {
  position: fixed;
  right: -240px;
  width: 240px;
  display: none;
  z-index: 1100;
  background: $white;
  top: 0px;
  padding-bottom: 20px;
  height: 100%;
  box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  .rpanel-title {
    display: block;
    padding: 24px 20px;
    color: $white;
    text-transform: uppercase;
    font-size: 15px;
    background: $themecolor;
    span {
      float: right;
      cursor: pointer;
      font-size: 11px;
      &:hover {
        color: $white;
      }
    }
  }
  .r-panel-body {
    padding: 20px;
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        padding: 5px 0;
      }
    }
  }
}

.shw-rside {
  right: 0px;
  width: 240px;
  display: block;
}

.chatonline {
  img {
    margin-right: 10px;
    float: left;
    width: 30px;
  }
  li a {
    padding: 13px 0;
    float: left;
    width: 100%;
    span {
      color: $bodytext;
      small {
        display: block;
        font-size: 10px;
      }
    }
  }
}

/*******************
 Right side toggle
*******************/

ul#themecolors {
  display: block;
  li {
    display: inline-block;
    &:first-child {
      display: block;
    }
    a {
      width: 50px;
      height: 50px;
      display: inline-block;
      margin: 5px;
      color: transparent;
      position: relative;
      &.working:before {
        content: "\f00c";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 18px;
        line-height: 50px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        color: $white;
        text-align: center;
      }
    }
  }
}

.default-theme {
  background: $muted;
}

.green-theme {
  background: $success;
}

.yellow-theme {
  background: $warning;
}

.red-theme {
  background: $danger;
}

.blue-theme {
  background: $info;
}

.purple-theme {
  background: $purple;
}

.megna-theme {
  background: $megna;
}

.default-dark-theme {
  background: $dark;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $muted 23%, $muted 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, $muted 23%, $muted 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $muted 23%, $muted 99%);
}

.green-dark-theme {
  background: $dark;
  ;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $success 23%, $success 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, #00c292 23%, $success 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $success 23%, $success 99%);
}

.yellow-dark-theme {
  background: $dark;
  ;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $danger 23%, $danger 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, $danger 23%, $danger 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $danger 23%, $danger 99%);
}

.blue-dark-theme {
  background: $dark;
  ;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $info 23%, $info 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, $info 23%, $info 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $info 23%, $info 99%);
}

.purple-dark-theme {
  background: $dark;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $purple 23%, $purple 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, $purple 23%, $purple 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $purple 23%, $purple 99%);
}

.megna-dark-theme {
  background: $dark;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $megna 23%, $megna 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, $megna 23%, $megna 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $megna 23%, $megna 99%);
}

.red-dark-theme {
  background: $dark;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 23%, $danger 23%, $danger 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 23%, $danger 23%, $danger 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 23%, $danger 23%, $danger 99%);
}

/*******************
 Footer
*******************/

.footer {
  bottom: 0;
  color: $bodytext;
  left: 0px;
  padding: 17px 15px;
  position: absolute;
  right: 0;
  border-top: 1px solid $border;
  background: $white;
}


/*******************
 scrollbar
*******************/

.slimScrollBar {
  z-index: 10!important;
}


/*******************
Megamenu
******************/

.mega-dropdown {
  position: static;
  width: 100%;
  .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px;
  }
  ul {
    padding: 0px;
    li {
      list-style: none;
    }
  }
  .carousel-item .container {
    padding: 0px;
  }
  .nav-accordion {
    .card {
      margin-bottom: 1px;
    }
    .card-header {
      background: $white;
      h5 {
        margin: 0px;
        a {
          text-decoration: none;
          color: $bodytext;
        }
      }
    }
  }
}


/*******************
Wave Effects
*******************/

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
  }
}

.waves-effect.waves-light {
  .waves-ripple {
    background-color: rgba(255, 255, 255, 0.45);
  }
}

.waves-effect.waves-red {
  .waves-ripple {
    background-color: rgba(244, 67, 54, 0.7);
  }
}

.waves-effect.waves-yellow {
  .waves-ripple {
    background-color: rgba(255, 235, 59, 0.7);
  }
}

.waves-effect.waves-orange {
  .waves-ripple {
    background-color: rgba(255, 152, 0, 0.7);
  }
}

.waves-effect.waves-purple {
  .waves-ripple {
    background-color: rgba(156, 39, 176, 0.7);
  }
}

.waves-effect.waves-green {
  .waves-ripple {
    background-color: rgba(76, 175, 80, 0.7);
  }
}

.waves-effect.waves-teal {
  .waves-ripple {
    background-color: rgba(0, 150, 136, 0.7);
  }
}

html body .waves-notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
  .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.waves-block {
  display: block;
}

/*******************
List inline
*******************/

ul.list-inline li {
  display: inline-block;
  padding: 0 8px;
}

/*******************
Table td vertical middle
*******************/

.vm.table td,
.vm.table th {
  vertical-align: middle;
}

.no-th-brd.table th {
  border: 0px;
}

.table.no-border {
  tbody {
    td {
      border: 0px;
    }
  }
}

/*******************
Other comon js for all pages
*******************/
.calendar-events {
  padding: 8px 10px;
  border: 1px solid $white;
  cursor: move;
  &:hover {
    border: 1px dashed $border;
  }
  i {
    margin-right: 8px;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


.single-column {
  .left-sidebar {
    display: none;
  }
  .page-wrapper {
    margin-left: 0px!important;
  }
}

.profile-tab,
.customtab {
  li {
    a.nav-link {
      border: 0px;
      padding: 15px 20px;
      color: $bodytext;
      &.active {
        border-bottom: 2px solid $themecolor;
        color: $themecolor;
      }
      &:hover {
        color: $themecolor;
      }
    }
  }
}

html body {
  .jqstooltip,
  .flotTip {
    width: auto!important;
    height: auto!important;
    background: $dark;
    color: $white;
    padding: 5px 10px;
  }
}

body .jqstooltip {
  border-color: transparent;
  border-radius: 60px;
}

/*******************
Notification page Jquery toaster
******************/
.jq-icon-info {background-color: $info; color:$white;}
.jq-icon-success {background-color: $success; color:$white;}
.jq-icon-error {background-color: $danger; color:$white;}
.jq-icon-warning{ background-color:$warning; color:$white;}

/*******************
form material page
******************/
.form-control-line
{
  .form-control{
    box-shadow:none;
  }
}

.table-responsive>.table-bordered{
  border: 1px solid $border;
}
