/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra:darrell@startuplab.mx>, June 2019
 */

.company-view {
	height: 100%;
}

.content {
	display: inline-block;
	font-weight: bold;
  justify-content: space-between;
}

.companyHeader {
	text-align: center;
}

.company-area textarea {
	width: 100% !important;
	height: 120px !important;
}

.companyContainer {
	min-height: 550px !important;
}

.companyTitle {
	margin: 10px 0;
}

.companyItem {
	display: inline-block;
	margin: 2px;
	width: calc(100% - 20px) !important;
}

.companyItem label{
	float: left;
  clear: left;
  text-align: left;
  width: 90px;
  margin-right: 5px;
}

.companyItem input{
	float: left;
	width: calc(80% - 10px);
}

.companyInfo {
	display: inline-flex;
}

	.companyLogo {
		width: 100px;
		height: 100px;
		margin: 30px 16px 0 0;
	}

	.companyLogo img {
		max-width: 100%;
	}

	.companyElements {
		margin: 0;
		list-style: none;
		padding: 0;
		width: calc(100% - 100px);
	}

	.selectLogo {
		width: 150px;
		height: 150px;
		cursor: pointer;
	}

	.bgImage {
		background-color: gray;
	}

	.bgHover {
		filter: brightness(0.4) !important;
	}

.team-button {
	z-index: 0;
}


/// Overrides the color of the buttons 
.btn-save {
	background-color: #707cd2 !important;
	border-color: #707cd2 !important;
	color: #ffffff;
	box-shadow: 0 14px 26px -12px rgba(112, 124, 210, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(112, 124, 210, 0.2) !important;
}

.btn-aux {
	background-color: #8898aa !important;
	border-color: #8898aa !important;
	color: #fff;
	box-shadow: 0 14px 26px -12px rgba(112, 124, 210, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(112, 124, 210, 0.2) !important;
}

.btn-warning {

	background-color: #ffc36d !important;
	border-color: #ffc36d !important;
	color: #ffffff;
}

.btn-danger {
	background-color: #ff5050 !important;
	border-color: #ff5050 !important;
	color: #ffffff;
}

.btn-stats {
	background-color: #707cd2 !important;
	border-color: #707cd2 !important;
	cursor: auto !important;
}
////////////

.btn-review {
	background-color: #ffb300 !important;
	border-color: #ffb300 !important;
}

.image-text {
	color: white;
  position: absolute;
  top: 45%;
  cursor: pointer;
  width: 150px;
  margin: 0 auto;
  text-align: center;
}

.sidebar-wrapper-open {
	margin-left: 240px !important;
}

.partners {
	color: #fff !important;
	margin: 5px;
}

.partners:nth-of-type(1) {
  float: right;
}

.pymeList {
	width: 100% !important;
	height: 320px;
}

	.pymeList li {
		margin: 5px 0;
		width: calc(100% - 20px) !important;
	}

	.pymeList input {
		width: calc(85% - 20px) !important;
	}

.btnCompany {
	display: inline-block;
	bottom: 0;
}

.btnOrder:nth-of-type(1) {
  float: left;
}
.btnOrder:nth-of-type(2) {
  float: right;
}
.btnOrder:nth-of-type(3) {
  float: right;
}

.showButton {
	display: none !important;
}

.text-error {
	font-size: 12px !important;
}

.DayPickerInput {
	display: block !important;
}

.fix-header .page-wrapper {
	height: 100vh !important;
}

.course-header .page-wrapper {
	min-height: 100% !important;
	height: auto !important;
	overflow: hidden !important;
}

.btn:not(:last-of-type) {
  margin-right: 16px;
}
.btnMarginClean{
	margin-right: 10px !important
}
.btnMarginClean:first-of-type{
	margin-right: 0px !important
}
._textarea textarea.form-control.is-invalid::focus {
  background-position: center bottom, center calc(100% - 22px) !important;
}
