/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

@import '../variable';

/*******************
 Right side panel
*******************/
.r-aside{
   padding-right:450px !important;
}
.right-side-panel{
   width:425px;
   padding:20px;
   padding-bottom:70px;
   background:$light;
   position:absolute;
   right:0px;
   top:0px;
   height: 100%;
   overflow-y:hidden;
}


/*******************
 Sales overview Chart
*******************/
#sales-overview{
   .ct-point{
      stroke-width:6px;
   }
   .ct-grid{
      stroke-dasharray:0px;
      stroke:$border;
   }
   .ct-area{
      fill-opacity:1;
   }
   .ct-series-a .ct-area, .ct-series-a .ct-point{
      fill:$primary;
      stroke:$primary;
   }
   .ct-series-b .ct-area, .ct-series-b .ct-point{
      fill:$info;
      stroke:$info;
   }
   .ct-series-c .ct-area, .ct-series-c .ct-point{
      fill:$light;
      stroke:$light;
   }
}

.website-visitor {
   .ct-series-a .ct-area {
      fill-opacity: 0.2;
      fill: url(#gradient);
      }
      .ct-series-a .ct-line, .ct-series-a .ct-point {
      stroke: $success;
      stroke-width: 2px;
      }
      .ct-series-b .ct-area {
         fill: $info;
         fill-opacity: 0.1;
      }
      .ct-series-b .ct-line, .ct-series-b .ct-point {
         stroke: $info;
         stroke-width: 2px;
      }
      .ct-series-a .ct-point, .ct-series-b .ct-point {
       stroke-width: 6px;
      }
   }

  .c3-chart-arcs-title{
   font-size:26px;
   font-weight:600;
   color:$bodytext;
   font-family:$bodyfont;
  }
/*******************
 Project of the month
*******************/
.pro-of-month{
   h6{
      font-weight:500;
      margin-bottom:0px;
   }
}
/*******************
 This is for responsive dashboard1 element
*******************/
@media(max-width:1023px) {
.r-aside{
      padding-right:25px;
   }
   .right-side-panel{
      // Commented to display the right-sidebar on cetificate view 
      // display:none;
   }
}
@media(max-width:767px) {
.r-aside{
      padding-right:15px;
   }
   #visitor{
      text-align: center;
   }

}
