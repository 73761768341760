/* latin */
@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 800;
  src: local('../assets/fonts/Museo700-Regular.otf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
#editor_area{
  background-color: #3c3c3cd9;
  overflow:scroll;
  display:flex;
  align-items: center;
  height:calc( 100vh - 180px) !important;
  max-height:100%;

}
#controls_area{
  overflow:scroll;
  align-items: center;
  height:calc( 100vh - 180px) !important;
  max-height:100%;
}
#editor_controls{
  overflow-y:auto;
  max-height:100%;
}
#drop_zone {
  border: 5px solid blue;
  width:  200px;
  height: 100px;
}
#range{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #00000042;
  position: absolute;
  top: 5px;
  left: 5px;
  height: 250px;
  width: 50px;
  border-radius: 1px;
}
#range input{
  rotate: 270deg;
}
#range label{
  font-weight: bold;
  font-size: 18px;
  color:white;
}
.wrapper-loader{
  position: absolute;
  background-color: #0006;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.wrapper-loader h1{
  color: white;
  font-weight: bold;
  margin-top: 30px;
}
.wrapper-loader h5{
  color: white;
}
